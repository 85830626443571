<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <g clip-path="url(#clip0_12639_17440)">
      <path
        d="M14.0007 25.6673C7.55715 25.6673 2.33398 20.4442 2.33398 14.0007C2.33398 7.55715 7.55715 2.33398 14.0007 2.33398C20.4442 2.33398 25.6673 7.55715 25.6673 14.0007C25.6673 20.4442 20.4442 25.6673 14.0007 25.6673ZM11.329 22.9455C10.178 20.504 9.51117 17.8626 9.36549 15.1673H4.73965C4.96667 16.9627 5.71023 18.6535 6.87998 20.0343C8.04974 21.4151 9.59533 22.4265 11.329 22.9455ZM11.7023 15.1673C11.8785 18.0128 12.6917 20.6857 14.0007 23.0447C15.345 20.6233 16.1303 17.9317 16.299 15.1673H11.7023ZM23.2617 15.1673H18.6358C18.4901 17.8626 17.8233 20.504 16.6723 22.9455C18.406 22.4265 19.9516 21.4151 21.1213 20.0343C22.2911 18.6535 23.0346 16.9627 23.2617 15.1673ZM4.73965 12.834H9.36549C9.51117 10.1387 10.178 7.49728 11.329 5.05582C9.59533 5.57484 8.04974 6.5862 6.87998 7.967C5.71023 9.3478 4.96667 11.0386 4.73965 12.834ZM11.7035 12.834H16.2978C16.1295 10.0697 15.3446 7.37811 14.0007 4.95665C12.6563 7.37801 11.871 10.0696 11.7023 12.834H11.7035ZM16.6723 5.05582C17.8233 7.49728 18.4901 10.1387 18.6358 12.834H23.2617C23.0346 11.0386 22.2911 9.3478 21.1213 7.967C19.9516 6.5862 18.406 5.57484 16.6723 5.05582Z"
        fill="#5ACCF0"
      />
    </g>
    <defs>
      <clipPath id="clip0_12639_17440">
        <rect width="28" height="28" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

